<template>
  <section v-loading="loading" class="page">
    <div>
      <div class="title">По данному авто не найдено<br />таймслотов</div>
      <div :class="['flex', { center: hasIncompleteParking }]">
        <MainButton
          v-if="!hasIncompleteParking"
          title="Стать в общую очередь"
          type="primary"
          size="big"
          width="half"
          @button-clicked="isModalOpen = true"
        />
        <MainButton
          title="Понятно"
          type="secondary"
          size="big"
          width="half"
          @button-clicked="$router.push({ name: 'terminal-carNumber' })"
        />
      </div>
    </div>

    <TechSupport />

    <CommonQueueModal
      v-if="isModalOpen"
      title="Проверьте номер авто для постановки в общую очередь:"
      :plate-truck="truck"
      description="Напоминаем, что регистрируетесь в общей очереди. Вы будете вызваны на
        выгрузку при отсутствии на парковке авто, приехавших по забронированным
        таймслотам."
      cancel="Отменить"
      accept="Подтвердить"
      @cancel="$router.push({ name: 'terminal-carNumber' })"
      @accept="checkHandLimit"
    />
  </section>
</template>

<script>
import {
  GET_IS_INCOMPLETE_PARKING,
  GET_TERMINAL_CURRENT_ID,
} from '@/store/actions'
import { mapGetters } from 'vuex'
import CommonQueueModal from '@/views/terminalUI/components/modals/CommonQueueModal'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'

import Api from '@/api'

export default {
  name: 'WithoutTimeslotArrived',
  components: { CommonQueueModal, MainButton, TechSupport },
  data() {
    return {
      truck: '',
      isModalOpen: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalId: GET_TERMINAL_CURRENT_ID,
      hasIncompleteParking: GET_IS_INCOMPLETE_PARKING,
    }),
  },
  mounted() {
    this.truck = this.$route.params.truck
  },
  methods: {
    async checkHandLimit() {
      try {
        const { data } = await Api.timeslots.checkHandLimit({
          unload_id: this.terminalId,
        })

        if (data['manual-ts-left']) {
          const cultures = await Api.control.culture.getActiveQuotasCultures({
            unload_id: this.terminalId,
          })

          // если есть и лимит ручных ТС, и квоты (список культур) - идём оформлять ручной ТС
          if (cultures.data && cultures.data.length) {
            await this.$router.push({
              name: 'terminal-handTimeslotForm',
              params: { truck: this.truck },
            })
          } else {
            // если нет культур (то есть нет открытых на ближайшие 3 дня квот) - до свидания
            await this.$router.push({
              name: 'terminal-noNearestQuotas',
            })
          }
        } else {
          // если лимит ручных ТС исчерпан - до свидания
          await this.$router.push({
            name: 'terminal-limitHandResponse',
            params: { hasTimeslot: false },
          })
        }
      } catch (e) {
        console.log(e.response.data)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 180px 11% 60px
  .title
    margin-bottom: 80px
    font-family: $fontPrimary
    font-size: 48px
    font-weight: 500
    line-height: 56px
  .flex
    display: flex
    gap: 32px
</style>
