var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page",
    },
    [
      _c("div", [
        _vm._m(0),
        _c(
          "div",
          { class: ["flex", { center: _vm.hasIncompleteParking }] },
          [
            !_vm.hasIncompleteParking
              ? _c("MainButton", {
                  attrs: {
                    title: "Стать в общую очередь",
                    type: "primary",
                    size: "big",
                    width: "half",
                  },
                  on: {
                    "button-clicked": function ($event) {
                      _vm.isModalOpen = true
                    },
                  },
                })
              : _vm._e(),
            _c("MainButton", {
              attrs: {
                title: "Понятно",
                type: "secondary",
                size: "big",
                width: "half",
              },
              on: {
                "button-clicked": function ($event) {
                  return _vm.$router.push({ name: "terminal-carNumber" })
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("TechSupport"),
      _vm.isModalOpen
        ? _c("CommonQueueModal", {
            attrs: {
              title: "Проверьте номер авто для постановки в общую очередь:",
              "plate-truck": _vm.truck,
              description:
                "Напоминаем, что регистрируетесь в общей очереди. Вы будете вызваны на\n      выгрузку при отсутствии на парковке авто, приехавших по забронированным\n      таймслотам.",
              cancel: "Отменить",
              accept: "Подтвердить",
            },
            on: {
              cancel: function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
              accept: _vm.checkHandLimit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v("По данному авто не найдено"),
      _c("br"),
      _vm._v("таймслотов"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }